(function($){

    /**
     * initializeBlock
     *
     * Adds custom JavaScript to the block HTML.
     *
     * @date    15/4/19
     * @since   1.0.0
     *
     * @param   object $block The block jQuery element.
     * @param   object attributes The block attributes (only available when editing).
     * @return  void
     */
    var initializeBlock = function( $block ) {
        //$block.find('img').doSomething();
        console.log("BLOCK | Init | ", "ACF Posts location grid")

    }

    // Initialize each block on page load (front end).
    $(document).ready(function(){
        $('#acf-posts-location-grid').each(function(){
            initializeBlock( $(this) );
        });
    });
    // Initialize dynamic block preview (editor).
    if( window.acf ) {
        //console.log("Window ACF")
        window.acf.addAction( 'render_block_preview', initializeBlock );
    }

})(jQuery);